const mapValues = require('lodash/mapValues');
const keyBy = require('lodash/keyBy');
const { appData } = require('./data.cjs');
const { convertDate } = require('../lib/shared-helpers.cjs');

const priceSuffix = (val, item) => item.unit ? `€ / ${item.unit}` : '€';
const pricePrefix = (val, item) => Number.isFinite(item.isPriceRange) ? 'ab' : '';

const { pages = {}, isCzech } = appData || {};

const featuredPages = [
	...Object.entries(pages).filter(([id, item]) => item?.pathname && id?.startsWith('assortment') && !item?.id.endsWith('_0')).map(([id, item], key) => ({
		id: item.id,
		title: item.title
	}))
];

const getFeaturedPages = (pageIds = [], subPages) => ([
	...pageIds.map(pageId => featuredPages.filter(item => item?.id === pageId || (subPages && item?.id?.startsWith(pageId)))).flat()
]);

const contacts = {
	lauter: {
		id: 'lauter',
		title: 'Lauter'
	},
	chemnitz: {
		id: 'chemnitz',
		title: 'Chemnitz'
	}
};

const conditions = [
	'Original verpackt',
	'Ausstellungsstück',
	'Vorführgerät'
];

const units = {
	'm²': {
		title: 'Quatratmeter'
	},
	'lfm': {
		title: 'Laufmeter'
	},
	'Stück': {
		title: 'Stück'
	},
	'Pack': {
		title: 'Pack'
	},
	'Rolle': {
		title: 'Rolle',
		stockUnit: 'Rolle(n)'
	},
	'Liter': {
		title: 'Liter'
	},
	'Eimer': {
		title: 'Eimer'
	}
};

const labels = [{
	id: 'isPublic',
	field: 'isPublic',
	title: 'öffentlich',
	filter: true
}, {
	id: 'isNotPublic',
	field: 'isPublic',
	title: 'nicht öffentlich',
	filter: false
}, {
	id: 'isSaleEntry',
	field: 'isSaleEntry',
	title: 'Angebot',
	filter: true
}, {
	id: 'isNotSaleEntry',
	field: 'isSaleEntry',
	title: 'kein Angebot',
	filter: false
}, {
	id: 'isOnSale',
	field: 'isOnSale',
	title: 'Aktionsartikel',
	filter: true
}, {
	id: 'isNotOnSale',
	field: 'isOnSale',
	title: 'kein Aktionsartikel',
	filter: false
}, {
	id: 'isDiscontinued',
	field: 'isDiscontinued',
	title: 'Auslaufartikel',
	filter: true
}, {
	id: 'isNotDiscontinued',
	field: 'isDiscontinued',
	title: 'kein Auslaufartikel',
	filter: false
}, {
	id: 'isSample',
	field: 'isSample',
	title: 'Muster',
	filter: true
}, {
	id: 'isNotSample',
	field: 'isSample',
	title: 'kien Muster',
	filter: false
}, {
	id: 'isPickup',
	field: 'isPickup',
	title: 'Abholung',
	filter: true
}, {
	id: 'isNotPickup',
	field: 'isSample',
	title: 'kiene Abholung',
	filter: false
}, {
	id: 'retail',
	field: 'retail',
	title: 'Einzelhandel',
	filter: true
}, {
	id: 'notRetail',
	field: 'retail',
	title: 'nicht Einzelhandel',
	filter: false
}, {
	id: 'wholesale',
	field: 'wholesale',
	title: 'Grosshandel',
	filter: true
}, {
	id: 'notWholesale',
	field: 'wholesale',
	title: 'nicht Grosshandel',
	filter: false
}, {
	id: 'shopLink',
	field: 'shopLink',
	title: 'Online-Shop',
	filter: { $exists: true }
}, {
	id: 'notShopLink',
	field: 'shopLink',
	title: 'nicht Online-Shop',
	filter: { $exists: false }
}, {
	id: 'profiShopLink',
	field: 'profiShopLink',
	title: 'Profi-Shop',
	filter: { $exists: true }
}, {
	id: 'notProfiShopLink',
	field: 'profiShopLink',
	title: 'nicht Profi-Shop',
	filter: { $exists: false }
}, {
	id: 'featuredPages',
	field: 'featuredPages',
	title: 'Vorgestellt',
	filter: { $exists: true }
}, {
	id: 'notFeaturedPages',
	field: 'featuredPages',
	title: 'nicht Vorgestellt',
	filter: { $exists: false }
}];

const stockUnits = Object.assign({}, ...Object.entries(units).map(([id, item], key) => ({
	[id]: {title: item.stockUnit ?? item.title}
})));

const fieldDefs = {
	id: {
		id: 'id',
		label: 'ID',
		order: 0,
		hidden: true,
		export: true
	},
	group: {
		id: 'group',
		label: 'Unterkategorie',
		order: 1,
		hidden: true,
		export: true
	},
	itemNo: {
		id: 'itemNo',
		label: 'Art.-Nr.',
		numeric: true,
		digits: 2,
		formatNumber: false,
		hidden: true,
		export: true,
		order: 3,
		hiddenAdmin: false
	},
	supplierItemNo: {
		id: 'supplierItemNo',
		label: 'Lieferant-Art.-Nr.',
		numeric: true,
		digits: 2,
		formatNumber: false,
		hidden: true,
		export: true,
		order: 3,
		hiddenAdmin: false
	},
	title: {
		id: 'title',
		label: 'Artikelbezeichnung',
		order: 2,
		value: (val, item) => `${val}${item.subtitle ? ` — ${item.subtitle}` : ''}${item.saleUnit ? ` (${item.saleUnit})` : ''}`,
		export: true
	},
	subtitle: {
		id: 'subtitle',
		label: 'Artikelbezeichnung 2',
		hidden: true,
		export: true,
		order: 4
	},
	inStock: {
		id: 'inStock',
		label: 'Lagerbestand',
		numeric: true,
		digits: 0,
		formatNumberOptions: {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2
		},
		order: 5,
		suffix: (val, item) => {
			const { stockUnit = item.unit } = (units && units[item.unit]) || {};
			return item.stockUnit ?? stockUnit ?? item.unit ?? 'Stück';
		},
		export: true,
		hiddenAdmin: true
	},
	length: {
		id: 'length',
		label: 'Länge',
		numeric: true,
		digits: 0,
		order: 6,
		suffix: 'mm',
		export: true,
		hiddenAdmin: true
	},
	width: {
		id: 'width',
		label: 'Breite',
		numeric: true,
		digits: 0,
		order: 7,
		suffix: 'mm',
		export: true,
		hiddenAdmin: true
	},
	height: {
		id: 'height',
		label: 'Höhe',
		numeric: true,
		digits: 0,
		order: 8,
		suffix: 'mm',
		export: true,
		hiddenAdmin: true
	},
	thickness: {
		id: 'thickness',
		label: 'Stärke',
		numeric: true,
		digits: 0,
		order: 9,
		suffix: 'mm',
		export: true,
		hiddenAdmin: true
	},
	wholesalePrice: {
		id: 'wholesalePrice',
		label: 'Preis (netto)¹',
		prefix: pricePrefix,
		suffix: priceSuffix,
		numeric: true,
		order: 10,
		formatNumberOptions: {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		},
		export: true
	},
	retailPrice: {
		id: 'retailPrice',
		label: 'Preis (brutto)²',
		prefix: pricePrefix,
		suffix: priceSuffix,
		numeric: true,
		order: 11,
		formatNumberOptions: {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		},
		export: true
	},
	suggestedPrice: {
		id: 'suggestedPrice',
		label: 'UVP',
		prefix: pricePrefix,
		suffix: priceSuffix,
		numeric: true,
		order: 12,
		formatNumberOptions: {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		},
		export: true,
		hidden: true,
		hiddenAdmin: true
	},
	discount: {
		id: 'discount',
		label: 'Rabatt',
		order: 13,
		numeric: true,
		suffix: '%',
		export: true,
		hidden: true
	},
	unit: {
		id: 'unit',
		label: 'Preis pro',
		order: 14,
		hidden: true,
		export: true
	},
	condition: {
		id: 'condition',
		label: 'Zustand',
		order: 15,
		list: conditions,
		value: (val) => conditions[val] || '',
		export: true,
		hidden: true
	},
	saleUnit: {
		id: 'saleUnit',
		label: 'Verkaufseinheit',
		order: 18,
		hidden: true,
		export: true
	},
	stockUnit: {
		id: 'stockUnit',
		label: 'Lagereinheit',
		order: 19,
		hidden: true,
		export: true
	},
	isOnSale: {
		id: 'isOnSale',
		label: 'Aktionsartikel',
		boolean: true,
		order: 20,
		hidden: true,
		export: true
	},
	isDiscontinued: {
		id: 'isDiscontinued',
		label: 'Auslaufartikel',
		boolean: true,
		order: 21,
		hidden: true,
		export: true
	},
	isSaleEntry: {
		id: 'isSaleEntry',
		label: 'Angebot',
		boolean: true,
		order: 22,
		hidden: true,
		export: true
	},
	isSample: {
		id: 'isSample',
		label: 'Handmuster',
		boolean: true,
		order: 23,
		hidden: true,
		export: true
	},
	isPickup: {
		id: 'isPickup',
		label: 'Abholung',
		boolean: true,
		order: 24,
		hidden: true,
		export: true
	},
	wholesale: {
		id: 'wholesale',
		label: 'Grosshandel',
		boolean: true,
		order: 25,
		hidden: true,
		export: true
	},
	retail: {
		id: 'retail',
		label: 'Einzelhandel',
		boolean: true,
		order: 26,
		hidden: true,
		export: true
	},
	isPublic: {
		id: 'isPublic',
		label: 'Öffentlich',
		boolean: true,
		order: 27,
		hidden: true,
		export: true
	},
	shopLink: {
		id: 'shopLink',
		label: 'Shop Link',
		order: 28,
		hidden: true,
		link: true,
		export: true
	},
	profiShopLink: {
		id: 'profiShopLink',
		label: 'Profi-Shop Link',
		order: 29,
		hidden: true,
		link: true,
		export: true
	},
	timestamp: {
		id: 'timestamp',
		label: 'Erstellt',
		value: convertDate,
		export: false,
		hidden: true,
		hiddenAdmin: false
	},
	publicFrom: {
		id: 'publicFrom',
		label: 'Öffentlich von',
		value: convertDate,
		export: false,
		hidden: true,
		hiddenAdmin: false
	},
	purchasePrice: {
		id: 'publicFrom',
		label: 'EK',
		hidden: true,
		skip: true
	},
	devaluation: {
		id: 'devaluation',
		label: 'Aberw.',
		hidden: true,
		skip: true
	},
	purchasePriceBenchmark: {
		id: 'purchasePriceBenchmark',
		label: 'Richt-EK',
		hidden: true,
		skip: true
	}
};

fieldDefs.title.sort = [
	fieldDefs.title.id,
	fieldDefs.subtitle.id
];

const fieldsExclude = {
	sale: ['isDiscontinued', 'isSaleEntry'],
	discontinued: ['isDiscontinued'],
	sample: ['isDiscontinued', 'isSaleEntry', 'isOnSale'],
	pickup: ['isDiscontinued', 'isSaleEntry', 'isOnSale', 'retail', 'wholesale', 'retailPrice']
};

const fields = [{
	...fieldDefs.group
}, {
	...fieldDefs.itemNo
}, {
	...fieldDefs.supplierItemNo
}, {
	...fieldDefs.title
}, {
	...fieldDefs.subtitle
}, {
	...fieldDefs.thickness
}, {
	...fieldDefs.width
}, {
	...fieldDefs.length
}, {
	...fieldDefs.inStock
}, {
	...fieldDefs.unit
}, {
	...fieldDefs.purchasePrice
}, {
	...fieldDefs.devaluation
}, {
	...fieldDefs.purchasePriceBenchmark
}, {
	...fieldDefs.suggestedPrice
}, {
	...fieldDefs.wholesalePrice
}, {
	...fieldDefs.retailPrice
}, {
	...fieldDefs.discount
}, {
	...fieldDefs.saleUnit
}, {
	...fieldDefs.stockUnit
}, {
	...fieldDefs.isOnSale
}, {
	...fieldDefs.isDiscontinued
}, {
	...fieldDefs.isSaleEntry
}, {
	...fieldDefs.wholesale
}, {
	...fieldDefs.retail
}, {
	...fieldDefs.isPublic
}, {
	...fieldDefs.id
}, {
	...fieldDefs.shopLink
}, {
	...fieldDefs.profiShopLink
}, {
	...fieldDefs.publicFrom
}, {
	...fieldDefs.timestamp
}];

const lists = isCzech ? {} : {
	aktion: {
		id: 'aktion',
		title: '100 Jahre HW',
		hiddenDiscontinued: true,
		order: 0,
		featuredPages: getFeaturedPages([
			'assortment1', 'assortment2', 'assortment3', 'assortment4', 'assortment5', 'assortment6'
		]),
		fields
	},
	bauen: {
		id: 'bauen',
		title: 'Bauen mit Holz',
		meta: {
			title: '${pages.assortment1.title}'
		},
		order: 1,
		featuredPages: getFeaturedPages([
			'assortment1', 'assortment1_1', 'assortment1_2'
		]),
		fields
	},
	tischlerware: {
		id: 'tischlerware',
		title: 'Tischlerware',
		meta: {
			title: '${pages.assortment2.title}'
		},
		order: 1,
		hiddenDiscontinued: true,
		featuredPages: getFeaturedPages([
			'assortment2'
		]),
		fields
	},
	platten: {
		id: 'platten',
		title: 'Platten & Sperrholz',
		meta: {
			title: '${pages.assortment3.title}'
		},
		order: 2,
		featuredPages: getFeaturedPages([
			'assortment3'
		], true),
		fields
	},
	baustoffe: {
		id: 'baustoffe',
		title: 'Baustoffe',
		meta: {
			title: 'Baustoffe'
		},
		order: 3,
		hiddenDiscontinued: true,
		featuredPages: getFeaturedPages([
			'assortment1', 'assortment1_3', 'assortment1_4', 'assortment1_5'
		]),
		fields
	},
	boden: {
		id: 'boden',
		title: 'Boden & Wand',
		order: 4,
		meta: {
			title: '${pages.assortment4.title}'
		},
		featuredPages: getFeaturedPages([
			'assortment4'
		], true),
		fields
	},
	garten: {
		id: 'garten',
		title: 'Garten & Terrasse',
		meta: {
			title: '${pages.assortment5.title}'
		},
		order: 5,
		featuredPages: getFeaturedPages([
			'assortment5'
		], true),
		fields
	},
	tueren: {
		id: 'tueren',
		title: 'Türen & Bauelemente',
		meta: {
			title: '${pages.assortment6.title}'
		},
		order: 6,
		featuredPages: getFeaturedPages([
			'assortment6'
		], true),
		fields
	},
	grillen: {
		id: 'grillen',
		title: 'Grill & Feuer',
		wholesale: false,
		order: 7,
		meta: {
			title: '${pages.assortment8.title}'
		},
		featuredPages: getFeaturedPages([
			'assortment8'
		], true),
		fields: [{
			...fieldDefs.group
		}, {
			...fieldDefs.itemNo
		}, {
			...fieldDefs.supplierItemNo
		}, {
			...fieldDefs.title
		}, {
			...fieldDefs.subtitle,
		}, {
			...fieldDefs.condition
		}, {
			...fieldDefs.suggestedPrice,
			hidden: false
		}, {
			...fieldDefs.wholesalePrice
		}, {
			...fieldDefs.retailPrice
		}, {
			...fieldDefs.discount
		}, {
			...fieldDefs.unit
		}, {
			...fieldDefs.inStock
		}, {
			...fieldDefs.saleUnit
		}, {
			...fieldDefs.stockUnit
		}, {
			...fieldDefs.isOnSale
		}, {
			...fieldDefs.isDiscontinued
		}, {
			...fieldDefs.isSaleEntry
		}, {
			...fieldDefs.wholesale
		}, {
			...fieldDefs.retail
		}, {
			...fieldDefs.isPublic
		}, {
			...fieldDefs.id
		}, {
			...fieldDefs.shopLink
		}, {
			...fieldDefs.profiShopLink
		}, {
			...fieldDefs.publicFrom
		}, {
			...fieldDefs.timestamp
		}]
	},
	farbe: {
		id: 'farbe',
		title: 'Farbe, Lasuren & Chemie',
		order: 8,
		meta: {
			title: 'Farbe, Lasuren & Chemie'
		},
		featuredPages: getFeaturedPages([
			'assortment5', 'assortment5_7'
		]),
		fields: fields.filter(item => !['width', 'height', 'thickness', 'length'].includes(item.id))
	},
	pflege: {
		id: 'pflege',
		title: 'Pflegeprodukte',
		hiddenDiscontinued: true,
		order: 9,
		meta: {
			title: 'Pflegeprodukte'
		},
		featuredPages: getFeaturedPages([
			'assortment5_7'
		]),
		fields: fields.filter(item => !['width', 'height', 'thickness', 'length'].includes(item.id))
	},
	geschenkartikel: {
		id: 'geschenkartikel',
		title: 'Geschenkartikel',
		hiddenDiscontinued: true,
		order: 10,
		featuredPages: getFeaturedPages([
			'assortment10'
		]),
		fields
	}
};

const categories = mapValues(keyBy(Object.entries(lists).map(([name, item], key) => ({
	key,
	id: name,
	title: item.title,
	order: item.order
})), 'id'));

const fieldsConfig = mapValues(lists, ({fields = []} = {}) => (
	mapValues(keyBy(fields, 'id'))
));

const filter = {
	sort: {
		publicFrom: -1,
		timestamp: -1
	}
};

module.exports = {
	featuredPages,
	fieldDefs,
	fieldsConfig,
	fieldsExclude,
	title: 'Auslaufartikel',
	lists,
	fields,
	categories,
	units,
	stockUnits,
	conditions,
	contacts,
	filter,
	labels,
	disclaimer: {
		retail: '* Alle Preise inkl. 19% MwSt. bzw. 7% (Bücher) | Nur solang Vorrat reicht. | Alle Angaben ohne Gewähr.',
		wholesale: '* Abholpreis exkl. MwSt. | Verfügbarkeit durch Zwischenverkauf unter Vorbehalt. | Alle Angaben ohne Gewähr.'
	}
};
